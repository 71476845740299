import { template as template_ac47a2a7f15b46d3850b1bb2b0eff48f } from "@ember/template-compiler";
const FKLabel = template_ac47a2a7f15b46d3850b1bb2b0eff48f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
