import { template as template_2bd936312cc5462e9acff6c03354e1ae } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2bd936312cc5462e9acff6c03354e1ae(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
